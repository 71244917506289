/** @jsxImportSource @emotion/react */
import React from 'react';
import {Link} from "react-router-dom";
import BgImage from "../SignIn-back.jpeg";
import Button from '@mui/material/Button';
import { css } from '@emotion/react'

export default function LP() {
    const LPWrapper = css({
        backgroundImage: `url(${BgImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
    });

    const h1Style = css({
        margin: '0px',
        paddingTop: 'calc(50vh - 3rem)',
        textAlign: 'center',
        color: '#ffffff',
        fontSize: '3rem'
    });

    return (
        <div css={LPWrapper}>
            <h1 css={h1Style}>
                Family Board
            </h1>
            <div style={{textAlign: 'center', padding: '1rem'}}>
                <Button
                    component={Link}
                    variant="contained"
                    style={{maxWidth: '300px'}}
                    fullWidth
                    to='/sign_in'
                    sx={{mt: 3, mb: 2}}
                >
                    Sign In
                </Button>
            </div>
        </div>
    );
}
