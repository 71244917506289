import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Schedule from '@mui/icons-material/Schedule';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

const ScheduleItem = (record: { color: any; bgColor: any; date: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; time: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
    return (<Card style={{color: record.color, backgroundColor: record.bgColor}}
                  variant="outlined" sx={{p: 1, mt: 1}}>
            <Box>
                <div style={{display: "inline-flex", alignItems: "center"}}>
                    <CalendarTodayIcon sx={{ fontSize: 16, mr: 1 }} />
                    <span style={{paddingRight: "1em", fontWeight:" bold"}}>
                        {record.date}
                    </span>
                </div>

                <div style={{display: "inline-flex", alignItems: "center"}}>
                    <Schedule sx={{ fontSize: 16, mr: 1 }} />
                    <span style={{fontWeight:" bold"}}>
                            {record.time}
                        </span>
                </div>
            </Box>
            {record.title}
        </Card>
    );
}

const data = [
    {date: "2022.11.02", time: "10:00", title: "高島屋", color: "#fff", bgColor: "#00f"},
    {date: "2022.11.02", time: "10:00", title: "高島屋", color: "#fff", bgColor: "#f00"},
    {date: "2022.11.02", time: "10:00", title: "高島屋", color: "#fff", bgColor: "#090"},
    {date: "2022.11.02", time: "10:00", title: "高島屋", color: "#fff", bgColor: "#666"},
];

const ScheduleList = () => {
    const userList = data.map((record, index) =>
        <ScheduleItem color={record.color} bgColor={record.bgColor}
                      date={record.date} time={record.time}
                      title={record.title} />
    );
    return (
        <>{userList}</>
    );
}

export default function Dashboard() {

    return (
        <Container maxWidth="sm" sx={{pt: 2}}>
            <Typography component="h2" style={{fontWeight:"bold"}}>
                直近の予定
            </Typography>

            <ScheduleList/>
        </Container>
    );
}
